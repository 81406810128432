import React from "react";
import ManagerAddSection from "./ManagerAddSection";
import ManagerListSection from "./ManagerListSection";
import { Area, Padding } from "@common/components";

const ManagerPage = () => {
  return (
    <Area>
      <Padding height={"20px"} />

      <ManagerAddSection />

      <Padding height={"40px"} />

      <ManagerListSection />
    </Area>
  );
};

export default ManagerPage;
